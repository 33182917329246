import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import type * as PWAStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { AppState } from 'o365.pwa.types.ts';

export class PWAState implements PWAStateModule.PWAState {
    static objectStoreDexieSchema: string = "&appId";

    public appId!: string;
    public appState!: AppState;
    public hasDatabaseConnection!: boolean;
    public isAppInstalled!: boolean;
    public appInstalledOrUpdatedTimestamp: Date | null | undefined;
    public hasHtmlUpdate!: boolean;
    public hasAppResourceUpdate!: boolean;
    public debugUi!: boolean;
    public debugMergeProc!: boolean;
    public skipCheckIn!: boolean;
    public hasServiceWorkerUpdate!: boolean;
    public hasLocalData!: boolean;
    public lastSync!: {
        [key: string]: Date
    };

    public get hasAppUpdateAvailable() {
        return this.hasHtmlUpdate || this.hasAppResourceUpdate || this.hasServiceWorkerUpdate;
    }

    constructor(appId: string, appState: AppState = 'OFFLINE', hasDatabaseConnection: boolean) {
        this.appId = appId;
        this.appState = appState;
        this.hasDatabaseConnection = hasDatabaseConnection;
        this.isAppInstalled = false;
        this.appInstalledOrUpdatedTimestamp = null;
        this.hasHtmlUpdate = false;
        this.hasAppResourceUpdate = false;
        this.debugUi = false;
        this.debugMergeProc = false;
        this.skipCheckIn = false;
        this.hasServiceWorkerUpdate = false;
        this.hasLocalData = false;
        this.lastSync = {};
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updatePWAState(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deletePWAState(this);
    }

    public async forceReload(): Promise<PWAState | null> {
        return await IndexedDbHandler.getPWAStateFromIndexedDB(this.appId);
    }
}

export default PWAState;
